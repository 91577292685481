<template>
  <div class="content-inner">
    <vs-card>
      <div class="create-newsletter-form">
        <div class="mb-4 flex items-center justify-between">
          <div class="vx-breadcrumb md:block hidden">
            <ul class="flex flex-wrap items-center">
              <li class="inline-flex item-center">
                <router-link :to="{ name: 'newsletters'}">Newsletters</router-link>
                <span class="breadcrumb-separator mx-2">
                  <span class="feather-icon select-none relative">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-chevrons-right"
                    >
                      <polyline points="13 17 18 12 13 7" />
                    </svg>
                  </span>
                </span>
              </li>
              <li class="inline-flex item-center">
                <a href="javascript:void(0)" class>Create newsletters</a>
                <span class="breadcrumb-separator mx-2">
                  <span class="feather-icon select-none relative">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-chevrons-right"
                    >
                      <polyline points="13 17 18 12 13 7" />
                    </svg>
                  </span>
                </span>
              </li>

              <li class="inline-flex items-center">
                <a href="javascript:void(0)" class>Untitled {{ moment().format('DD/MM/YYYY') }}</a>
              </li>
            </ul>
          </div>
        </div>
        <h2 class="mb-4">{{ moment().format('DD/MM/YY')}}</h2>
        <p class="w-full md:w-1/2 mb-10 text-dark">
          <strong class="mr-4">Author:</strong>
          {{ authorName }}
        </p>
        <div class="input-wrap mb-5">
          <vs-input
            v-validate="'required'"
            data-vv-validate-on="blur"
            data-vv-validate-as="Title of newsletter"
            name="title"
            v-model="newsLetterData.title"
            label-placeholder="Title of newsletter"
            class="w-full md:w-1/2"
          />
          <span class="text-danger text-sm">{{ errors.first('title') }}</span>
        </div>
        <div class="input-wrap mb-5">
          <label class="ml-2">Add introduction</label>

          <quillEditor :pages="newsLetterData.introduction" class="w-full md:w-1/2"></quillEditor>
          <span class="text-danger text-sm">{{ errors.first('introduction') }}</span>
        </div>
        <div class="mb-base photo-wrapper">
          <ul class="photos-list">
            <li v-for="(image, index) in media" :key="index" style="display: inline-block">
              <a
                href="javascript::void(0)"
                @click.prevent="openGallery(index)"
              >
              <img v-lazy="image.src || image.thumb" style="height: 100px" class="object-cover"/>
              <feather-icon icon="XIcon" class="m-0 cursor-pointer" @click="removeImage(index)" />
              </a>
            </li>
          </ul>
        </div>
        <div class="w-full md:w-1/2 mb-5" align="right">
          <input
            type="file"
            class="hidden"
            name="file"
            ref="uploadImgInput"
            multiple
            @change="uploadImage($event.target.files)"
            accept="image/*"
          />
          <vs-button class="btn-blue-border" @click="$refs.uploadImgInput.click()" type="border">Upload Image</vs-button>
        </div>
        <div class="w-full md:w-1/2 mb-5">
          <div class="select-wrapper">
            <v-select
              :options="centreOptions"
              :clearable="false"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              v-model="centreFilter"
            >
              <template #header>
                <label for class="label">Select Learning Centre</label>
              </template>
              <template #open-indicator="{ attributes }">
                <span v-bind="attributes">
                  <vs-icon icon="arrow_drop_down"></vs-icon>
                </span>
              </template>
            </v-select>
          </div>
        </div>
        <div class="w-full md:w-1/2 mb-5">
          <div class="select-wrapper">
            <v-select
              multiple
              :options="roomOptions"
              :clearable="false"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              v-model="roomFilter"
            >
              <template #header>
                <label for class="label">Select Rooms</label>
              </template>
              <template #open-indicator="{ attributes }">
                <span v-bind="attributes">
                  <vs-icon icon="arrow_drop_down"></vs-icon>
                </span>
              </template>
            </v-select>
          </div>
        </div>
        <div class="input-wrap mb-5" v-if="activitiesTitle.length">
          <label class="ml-2">Added Activities</label>
          <vs-input
            disabled="!isActivityAdded"
            v-validate="'required'"
            data-vv-validate-on="blur"
            data-vv-validate-as="Add Activity"
            name="activities"
            v-model="activitiesTitle"
            class="w-full md:w-1/2"
          />
          <span class="text-danger text-sm">{{ errors.first('activities') }}</span>
        </div>
        <div class="w-full md:w-1/2" align="right">
          <vs-button class="btn-blue-border" @click="addActivity" type="border">Add Activity</vs-button>
        </div>
        <div class="input-wrap editEmail">
          <label class="ml-2">Add exit</label>

          <quillEditor :pages="newsLetterData.existText" class="w-full md:w-1/2"></quillEditor>

          <!--          <vs-textarea-->
          <!--            name="existText"-->
          <!--            v-model="newsLetterData.existText"-->
          <!--            label="Add exit"-->
          <!--            class="w-full md:w-1/2 mt-5"-->
          <!--            v-bind:class="{ hasValue: newsLetterData.existText }"-->
          <!--          />-->
          <span class="text-danger text-sm">{{ errors.first('existText') }}</span>
        </div>
        <div class="w-full md:w-1/2 mt-6" align="right">
          <vs-button :disabled="!isFormValid" @click="createNewsletter">Review Newsletter</vs-button>
        </div>
      </div>
    </vs-card>
    <vs-popup class="activityPopUp ph-popup-md" title :active.sync="addActivityPopUp">
      <div>
        <div  style="position:sticky; top:0;background:white; z-index:1000;width:auto;">
           <vs-button class="m-3" align="right" @click="saveActivity">
            Add Activity</vs-button>
         </div>
         <div class="flex datepicker-wrap">
          <span class="btn btn-outline-primary"
            ><i class="feather icon-chevron-left" @click="previousWeek"></i
          ></span>
          <span class="reportrange-text">
            <date-picker
              v-model="week"
              type="week"
              placeholder="Select week"
              prefix-class="xmx"
              ref="picker"
              @change="pickerChange"
              :lang="lang"
              :clearable="false"
              :show-week-number="false"
            >
              <template v-slot:input="{ emit }">{{ dateRange }}</template>
            </date-picker>
          </span>
          <span class="btn btn-outline-primary"
            ><i class="feather icon-chevron-right" @click="nextWeek"></i
          ></span>
        </div>


        <div id="data-list-thumb-view" class="data-list-container nl-create-popup vs-table vs-table--tbody-table w-full">
          <table class="vs-table vs-table--tbody-table mt-8" width="100%">
            <thead class="vs-table--thead" style="position: sticky; top: 63px;">
            <tr>
              <th class="px-4 leading-none">Contributor</th>
              <th class="px-4 leading-none">Activity</th>
              <th class="px-4 leading-none">Core Skill</th>
              <th class="px-4 leading-none">Ways of Learning</th>
              <th class="px-4 leading-none">STREAM</th>
              <th class="px-4 leading-none">Select</th>
            </tr>
            </thead>
            <tbody>
            <tr
              v-if="activities.length > 0"
              v-for="activity in activities"
              :key="activity._id"
              class="tr-values vs-table--tr tr-table-state-null"
            >
              <td class="td vs-table--td">{{ activity.contributor[0].fullName }}</td>
              <td class="td vs-table--td">
                <p class="font-bold mb-1">{{ activity.name }}</p>
                <p>{{ activity.purposeStatement }}</p>
              </td>
              <td class="td vs-table--td text-center">
                <img
                  v-if="activity.coreSkill[0].icon !==''"
                  :src="activity.coreSkill[0].icon"
                  class="h-16 object-contain mb-4 mx-auto block"
                />
                {{ activity.coreSkill ? activity.coreSkill[0].name : '' }}
              </td>
              <td class="td vs-table--td text-center">
                <img
                  v-if="activity.waysOfLearning[0].icon"
                  :src="activity.waysOfLearning[0].icon"
                  class="h-16 object-contain mb-4 mx-auto block"
                />
                {{ activity.waysOfLearning ? activity.waysOfLearning[0].name : '' }}
              </td>
              <td class="td vs-table--td text-center">
                <img
                  v-if="activity.streams[0].icon"
                  :src="activity.streams[0].icon"
                  class="h-16 object-contain mb-4 mx-auto block"
                />
                {{ activity.streams ? activity.streams[0].name : '' }}
              </td>
              <td class="td vs-table--td">
                <label class="form-checkbox">
                  <input type="checkbox" :value="activity._id" v-model="selectedActivities" />
                  <i class="form-icon"></i>
                </label>
                <!--                <vs-checkbox v-model="selectedActivities" vs-value={{activity._id}}></vs-checkbox>-->
                <!--                <input name="selectedChildren" type="checkbox" v-model="selectedActivities" vs-value="activity">-->
              </td>
            </tr>
            <tr v-if="activities.length <= 0">
              <td colspan="6">No record found.</td>
            </tr>
            </tbody>
          </table>
        </div>

      </div>
    </vs-popup>
    <LightBox
        ref="lightbox"
        :media="media"
        :show-light-box="false"
        :show-caption="false"
        :show-thumbs="false"
        @onImageChanged="onImageChanged"
      />
  </div>
</template>

<script>
  import moment from "moment";
  import vSelect from "vue-select";
  import { mapActions } from "vuex";
  import VueLodash from "vue-lodash";
  import lodash from "lodash";
  import Vue from "vue";
  import Swal from "sweetalert2/dist/sweetalert2";
  import "sweetalert2/src/sweetalert2.scss";
  import quillEditor from '../../../Editor';
  import DatePicker from "vue2-datepicker";
  import LightBox from "vue-image-lightbox";
import "vue-image-lightbox/dist/vue-image-lightbox.min.css";

  Vue.use(VueLodash, { name: "custom", lodash: lodash });

  export default {
    components: {
      "v-select": vSelect,
      quillEditor,
      DatePicker,
      LightBox
    },
    data() {
      return {
        newsLetterData: {
          date: "",
          title: "",
          introduction: {content: null},
          photos: null,
          existText: {content: null},
          hasPublished: false,
          activities: null,
          createdBy: this.$store.state.AppActiveUser._id,
          learningCenterId: "",
          rooms: [],
        },
        authorName: this.$store.state.AppActiveUser.fullName,
        centreOptions: [],
        centreFilter: { label: "Select Learning Center", value: "all" },
        roomOptions: [],
        roomFilter: '',
        addActivityPopUp: false,
        currentImageIndex: 0,
        media: [],
        roomData: [],
        photos: [],
        activities: [],
        selectedActivities: [],
        activitiesTitle: [],

        week: new Date(),
      lang: {
        formatLocale: {
          firstDayOfWeek: 1
        },
        monthBeforeYear: false
      },
      dateRange: this.startOfWeek() + " - " + this.endOfWeek(),
      startDate: this.startOfWeek(),
      endDate: this.endOfWeek()
      };
    },
    watch: {
      centreFilter(obj) {
        let data = obj.roomsData;
        let newData = data.slice(1);
        this.roomFilter = "";
        this.roomOptions = newData;
      },
    },
    computed: {
      isFormValid() {
        return (
          !this.errors.any() &&
          this.newsLetterData.title &&
          this.newsLetterData.introduction &&
          // this.newsLetterData.activities &&
          this.centreFilter.value != "Select Learning Center" &&
          this.roomFilter.value != ""
        );
      },
      isActivityAdded() {
        return(
          this.activitiesTitle.length != 0
        )
      }
    },
    methods: {
      ...mapActions("newsletter", [
        "getRoomList",
        "saveNewsletter",
        "uploadImageToNewsletter",
        "getActivitiesList",
      ]),
      openGallery(index) {
        this.$refs.lightbox.showImage(index);
        this.currentImageIndex = index;
      },
      onImageChanged(index) {
        this.currentImageIndex = index;
      },
      pickerChange(value) {
      let sDate = this.startOfWeek(value, true);
      let eDate = this.endOfWeek(value, true);
      this.startDate = sDate;
      this.endDate = eDate;

      this.dateRange = sDate + " - " + eDate;

      this.addActivity();
    },
    previousWeek() {
      let currentDate = this.startDate;

      let d = new Date(currentDate);

      let a = d.setDate(d.getDate() - 7);

      this.startDate = this.startOfWeek(a);
      this.endDate = this.endOfWeek(a);
      this.dateRange = this.startDate + " - " + this.endDate;

      this.addActivity();
    },
    nextWeek() {
      let currentDate = this.startDate;

      let d = new Date(currentDate);

      let a = d.setDate(d.getDate() + 7);

      this.startDate = this.startOfWeek(a);
      this.endDate = this.endOfWeek(a);
      this.dateRange = this.startDate + " - " + this.endDate;

      this.addActivity();
    },
    startOfWeek(paramDate = new Date(), fromPicker = false) {
      let date = new Date(paramDate);
      let diff =
        date.getDate() -
        date.getDay() +
        (date.getDay() === 0 ? -6 : 1) +
        (fromPicker ? 1 : 0);
      this.week = date;
      return this.moment(date.setDate(diff)).format("MMMM DD YYYY");
    },
    endOfWeek(paramDate = new Date(), fromPicker = false) {
      let date = new Date(paramDate);

      let lastDate =
        date.getDate() - (date.getDay() - 1) + 6 + (fromPicker ? 1 : 0);

      return this.moment(date.setDate(lastDate)).format("MMMM DD YYYY");
    },
    moment(date) {
      return moment(date);
    },
      showMessage(title, message, color) {
        this.$vs.notify({
          title: title,
          text: message,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: color,
        });
      },
      async uploadImage(file) {
        if (this.photos.length < 5) {
          this.$vs.loading();
          if (file.length > 0) {
            let obj = new FormData();
            for (let i = 0; i < file.length; i++) {
              obj.append("file", file[i]);
            }
            let data = {
              obj: obj,
              config: {
                header: {
                  "Content-Type": "image/png",
                },
              },
            };

            this.uploadImageToNewsletter(data)
              .then(async (res) => {
                let location = res.data.data;
                if (this.photos.length > 0) {
                  this.photos = this.photos.concat(location);
                } else {
                  this.photos = location;
                }
                this.media = await this.photos.map((item) => {
                  return {
                    thumb: item.photo,
                    src: item.photo,
                    srcset: item.photo,
                  };
                });
                this.$vs.loading.close();
              })
              .catch((err) => {
                //console.log(err);
                this.$vs.loading.close();
              });
          }
        } else {
          this.showMessage("Failed", "You can only upload five photos", "danger");
          // this.$vs.loading.close()
        }
      },
      removeImage(imageId) {
        let self = this;
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, remove it!",
        }).then((result) => {
          if (result.value) {
            //self.$vs.loading();
            self.media.splice(imageId, 1);
            self.photos.splice(imageId, 1);
            //self.$vs.loading.close();
          }
        });
      },
      async createNewsletter() {
        this.newsLetterData.date = this.moment().format("DD/MM/YY");
        let a = await this.roomFilter.map((item) => item.value);
        this.newsLetterData.rooms = a;
        this.newsLetterData.learningCenterId = this.centreFilter.value;
        if (this.photos != null) {
          this.newsLetterData.photos = this.photos;
        }
        this.$vs.loading();
        this.saveNewsletter(this.newsLetterData)
          .then((res) => {
            if (res.status === 200) {
              this.showMessage(
                "Success",
                "Newsletter created successfully.",
                "success"
              );
              this.$vs.loading.close();
              this.$router.push({ name: "newsletters" });
            }
          })
          .catch((err) => {
            if (err.response.status === 403) {
              this.showMessage("Failed", err.response.data.message, "danger");
            }
            this.$vs.loading.close();
          });
      },
      async addActivity() {
        if (this.centreFilter.value != "all" && this.roomFilter.value != "all") {
          let a = await this.roomFilter.map((item) => item.value);
          let info = {
              centreId: this.centreFilter._id,
              rooms: a,
              startDate: this.startDate,
              endDate: this.endDate
            };
          await this.getActivitiesList(info)
            .then((res) => {
              this.activities = res.data.data;
              this.addActivityPopUp = true;
            })
            .catch((err) => {
              this.$vs.loading.close();
            });
          this.$vs.loading.close();
        } else {
          this.showMessage(
            "Failed",
            "Learning Centre and rooms should be selected should be selected first",
            "danger"
          );
        }
      },
      async saveActivity() {
        this.newsLetterData.activities = this.selectedActivities;
        this.addActivityPopUp = false;
        let b = [];
        if (this.selectedActivities.length) {
          for (let i = 0; i < this.selectedActivities.length; i++) {
            let a = await this.lodash.filter(this.activities, {
              _id: this.selectedActivities[i],
            });
            a = a.map((item) => item.name);
            b.push(a[0]);
          }
        }
        this.activitiesTitle = b;
      },
    },
    created() {
      this.getRoomList().then(async (res) => {
        this.centreOptions = this.$store.state.newsletter.centreOptions;
      });
    },
  };
</script>
<style lang="scss">
  .input-wrap {
    padding: 0;
    @media (max-width: 576px) {
      padding: 0;
    }
  }

  .activityPopup {
    .vs-popup--title {
      visibility: hidden;
    }

    .vs-card {
      min-width: 90%;

      p {
        font-size: 16px;
        line-height: 30px;
        margin-bottom: 5px;
      }

      .con-select {
        .vs-select--input {
          padding: 20px;
          border: 1px solid #838383;
          font-size: 16px;
          line-height: 20px;
        }
      }
    }
  }

  .photo-wrapper {
    .vs-button {
      display: inline-block;
      margin-top: 20px;
    }
  }
  .vs-table--tbody-table:nth-child(2n){
    background-color: #fff;
  }

  // .photos-list {
  //   margin-top: 20px;

  //   li {
  //     cursor: pointer;
  //     width: 145px;
  //     height: 100px;
  //     margin-right: 10px;
  //     position: relative;

  //     img {
  //       width: 100%;
  //       height: 100%;
  //       object-fit: scale-down;
  //     }

  //     .feather-icon {
  //       position: absolute !important;
  //       top: -10px;
  //       right: -15px;
  //       background: #ea5354;
  //       color: #ffffff !important;
  //       width: 30px;
  //       height: 30px;
  //       border-radius: 50%;
  //       display: flex;
  //       align-items: center;
  //       justify-content: center;
  //       opacity: 0;
  //       visibility: none;
  //       transition: 0.4s all ease-in-out;
  //     }

  //     &:hover {
  //       .feather-icon {
  //         opacity: 1;
  //         visibility: visible;
  //       }
  //     }
  //   }
  // }
</style>
